<template>
    <div>
    <v-card class="elevation-0 transparent">
        <v-card-title>
            Пользователи
        </v-card-title>
        <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
                <v-btn v-on="on" v-bind="attrs" icon v-on:click="addUser"><v-icon color="green">mdi-plus</v-icon></v-btn>
            </template>
            <span>Добавить пользователя</span>
        </v-tooltip>

        <v-data-table :items="users" :headers="headers">
            <template v-slot:item.actions="{item}">
                <v-icon color="orange" v-if="editableId !== item['@User']" v-on:click="editItem(item)">mdi-pencil</v-icon>
                <v-icon color="green" v-else v-on:click="saveUser(item)">mdi-check</v-icon>
                <v-icon color="red" v-on:click="deleteUser(item)">mdi-delete</v-icon>
            </template>

            <template v-slot:item.Role="{item}">
                <v-select
                        :items="rolesItems"
                        v-model="item.Role"
                        style="cursor: pointer"
                        :readonly="editableId !== item['@User']"
                        item-text="Name"
                        item-value="@Role"
                >

                </v-select>
            </template>
            <template v-slot:item.Avatar="{item}">
                <v-avatar v-if="item.Avatar && item.Avatar.length > 0">
                <v-img max-width="100" :src="getStatic(item.Avatar)"

                >
                </v-img>
                </v-avatar>
                <p v-else-if="editableId !== item['@User']">Нет изображения</p>
                <template v-if="editableId === item['@User']">
                    <v-icon v-if="item.Avatar && item.Avatar.length > 0" color="red" v-on:click="item.Avatar=null">
                    mdi-close</v-icon>
                    <v-file-input
                            v-else
                            prepend-icon=""
                            outlined dense
                            placeholder="Выберите изображение"
                            accept="image/*" class="py-2" v-model="selectedFile" />
                </template>

            </template>
            <template v-slot:item.Name="{item}">
                <p v-if="editableId !== item['@User']" v-html="item.Name"></p>
                <v-text-field v-else v-model="item.Name"></v-text-field>
            </template>
            <template v-slot:item.Password="{item}">
                <div class="d-flex flex-row">
                <p v-html="'*'.repeat(item.Password ? item.Password.length : 5)" v-if="editableId !== item['@User']" v-on:click="setChangePassword(item)"></p>
                <v-text-field v-else v-model="newPassword"></v-text-field>
                <v-icon v-if="editableId === item['@User']" color="red" v-on:click="restorePassword(item)">mdi-restore</v-icon>
                </div>
            </template>
            <template v-slot:item.Login="{item}">
                <p v-if="editableId !== item['@User'] && item['@User'] > 0" v-html="item.Login"></p>
                <v-text-field v-else v-model="item.Login"></v-text-field>
            </template>
        </v-data-table>
    </v-card>
        <v-card elevation="0" class="transparent">
            <v-card-title>
                Роли
            </v-card-title>
            <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                    <v-btn v-on="on" v-bind="attrs" icon v-on:click="addRole"><v-icon color="green">mdi-plus</v-icon></v-btn>
                </template>
                <span>Добавить роль</span>
            </v-tooltip>
            <v-data-table :items="rolesItems" :headers="rolesHeaders">
                <template v-slot:item.actions="{item}">
                    <v-icon color="orange" v-if="editableRoleId !== item['@Role']" v-on:click="editRole(item)">mdi-pencil</v-icon>
                    <v-icon color="green" v-else v-on:click="saveRole(item)">mdi-check</v-icon>
                    <v-icon color="red" v-on:click="deleteRole(item)">mdi-delete</v-icon>
                </template>
                <template v-slot:item.Name="{item}">
                    <p v-if="editableRoleId !== item['@Role']">{{item.Name}}</p>
                    <v-text-field v-else v-model="item['Name']"></v-text-field>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import {getStatic, getURL} from "../../settings";
    import axios from "axios";

    export default {
        name: "Employees",
        data: () => {
            return {
                users: [],
                headers: [{text: 'Идентификатор'}, {text: 'Имя'}, {text: 'Логин'}, {text: 'Пароль'}, {text: 'Роль'}, {text: 'Фотография'}],
                editableId: null,
                TableMap: {
                    'actions': 'Действия',
                    '@User': 'Идентификатор',
                    'Avatar': 'Фотография',
                    'Password': 'Пароль',
                    'Login': 'Логин',
                    'Name': 'Имя',
                    'Role': 'Роль'
                },
                emptyUser: {
                    '@User': -1,
                    'Avatar': null,
                    'Login': null,
                    'Password': '',
                    'Name': null,
                    'Role': 'Admin'
                },
                newPassword: '',
                selectedFile: null,
                editableItem: null,
                rolesItems: [],
                rolesHeaders: [
                    {text: 'Идентификатор', value: '@Role'},
                    {text: 'Название', value: 'Name'},
                    {text: 'Действия', value: 'actions'}],
                rolesTableMap: {
                    'actions': 'Действия',
                    '@Role': 'Идентификатор',
                    'Name': 'Название роли'
                },
                editableRoleId: null,
                emptyRole: {
                    '@Role': -1,
                    'Name': null
                },
                editableRole: null
            }
        },
        mounted() {
            this.loadUsers();
            this.loadRoles();
        },
        methods: {
            editItem(item) {
              this.editableItem = item;
              this.editableId = item['@User'];
            },
            loadRoles() {
              axios.get(getURL('admin/role/list')).then(resp => {
                  this.rolesItems = resp.data;
                  this.rolesHeaders = resp.data.headers || this.rolesHeaders;
                  if(!this.rolesHeaders.length && this.rolesItems.length > 0) {
                      this.rolesHeaders = Object.keys(this.rolesItems[0]).map(field => {return {text: this.TableMap[field], value: field}});
                      this.rolesHeaders.push({text:'Действия', value: 'actions'})
                  }
              })
            },
            loadUsers() {
                axios.get(getURL('admin/user_list'), {params: {showGuest: false}}).then(resp => {
                    this.users = resp.data;
                    this.headers = resp.data.headers || [];
                    if(!this.headers.length && this.users.length > 0) {
                        this.headers = Object.keys(this.users[0]).map(field => {return {text: this.TableMap[field], value: field}});
                        this.headers.push({text:'Действия', value: 'actions'})
                    }
                })
            },
            deleteUser(item) {
                axios.delete(getURL(`admin/user/${item["@User"]}`)).then(() => {
                    this.loadUsers();
                })
            },
            setChangePassword(item) {
                this.editableId =  item['@User'];
                this.editableItem = item;
                this.newPassword = '';
            },
            restorePassword(item) {
                axios.post(getURL(`admin/user/password/${item['@User']}`), {password: this.newPassword}).then(() => {
                    this.loadUsers();
                    this.newPassword = '';
                })
            },
            addUser() {
              this.users.push(this.emptyUser);
                this.editableId = -1;
            },
            addRole() {
              this.rolesItems.push(this.emptyRole);
              this.editableRoleId = -1;
            },
            editRole(item) {
                this.editableRole = item;
                this.editableRoleId = item['@User'];
            },
            deleteRole(item) {
                item['action'] = 'delete';
                axios.post(getURL('admin/role'), item).then(() => {
                    this.loadRoles();
                    this.editableRoleId = null;
                    this.editableRole = null;
                })
            },
            saveRole(item) {
              item['action'] = item['@Role'] < 0 ? 'create' : 'update';
              axios.post(getURL('admin/role'), item).then(() => {
                  this.loadRoles();
                  this.editableRoleId = null;
                  this.editableRole = null;
              })
            },
            saveUser(item) {
              if(item['@User'] < 0) {
                  item['action'] = 'create';
              } else {
                item['action'] = 'update';
                item['Password'] = this.newPassword;
              }
              axios.post(getURL('admin/user'), item).then(() => {
                  this.loadUsers();
                  this.editableId = null;
                  this.editableItem = null;
                  this.selectedFile = null;
              });
            },
            getURL, getStatic
        },
        watch: {
            selectedFile(value) {
                if ( value && this.editableItem ) {
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(this.selectedFile);
                    reader.onload = () => {
                        const blob = new Blob([reader.result]);
                        const formData = new FormData();
                        formData.append('image', blob);
                        formData.append('action', 'create');
                        formData.append('type', value.type);
                        formData.append('name', value.name);
                        axios.post(getURL('admin/content'), formData, {withCredentials:true})
                            .then(response=> {
                                this.editableItem.Avatar = response.data;
                            })
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
