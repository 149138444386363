<template>
    <div class="d-flex flex-row justify-start align-start">
        <div class="d-flex flex-column">
            <v-card-title>
                Задачи
            </v-card-title>
            <v-card-actions>
                <v-menu>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn color="transparent" elevation="0"
                               v-on="on"
                               class="text-left"
                               v-bind="attrs"
                               block
                        >Создать
                            <v-icon color="green">mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-list offset-y>
                        <v-list-item v-for="regulation of regulations" :key="`regulation-${regulation['@Regulation']}`">
                            <v-list-item-content class="py-0">
                                <v-btn v-on:click="createTask(regulation)" color="transparent" elevation="0">
                                    {{ regulation.Name}}
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-actions>
            <v-divider></v-divider>
            <v-navigation-drawer>
                <v-list v-for="[name, menus] in Object.entries(taskMenus)" :key="name">
                    <v-list-item dense>
                        <v-list-item-title>
                            {{name}}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item dense v-for="taskMenu of menus" :key="taskMenu.link" :link="true"
                                 v-on:click="taskFilter = taskMenu.link">
                        <v-list-item-icon v-if="taskMenu.icon">
                            <v-icon>{{taskMenu.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <template v-if="taskMenu.count"><b>{{taskMenu.count}}</b> <v-divider vertical></v-divider></template>
                            {{taskMenu.name}}
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                </v-list>
            </v-navigation-drawer>
        </div>

        <template v-if="true">
            <template v-if="currentTasks && currentTasks.length > 0">
                <v-list style="max-height: 90vh; overflow-y: auto">
                    <v-list-item v-for="task of currentTasks" :key="task['@Task']" class="py-2">
                        <horizontal-task :info="task"
                                         :employees="employees"
                                         :regulations="regulations"
                                         :task-states="taskStates"
                        ></horizontal-task>
                    </v-list-item>
                </v-list>
            </template>
            <template v-else>
                <div class="d-flex flex-column" style="width: 100%;height: 100%">
                    <v-img class="mt-16" height="300" width="300" contain alt="path-to-create" src="../../assets/tasks/path_to_create.png"></v-img>
                    <v-card-title>Нет задач в текущем разделе</v-card-title>
                    <v-card-subtitle>Выпишите задачу или подождите, пока выпишут вам</v-card-subtitle>
                </div>
            </template>
        </template>
        <template v-else>
            <v-skeleton-loader></v-skeleton-loader>
        </template>
    </div>
</template>

<script>
    import HorizontalTask from "../../components/tasks/HorizontalTask";
    import axios from "axios";
    import {getURL} from "../../settings";
    import eventBus from "../../utils/eventBus";

    export default {
        name: "Tasks",
        components: {HorizontalTask},
        data: () => {
            return {
                taskLoading: false,
                taskMenus: {},
                initialMenus: {
                    'Основные': [
                        {
                            name: 'Все',
                            link: 'all',
                            icon: 'mdi-select-all',
                            count: 0
                        },
                        {
                            name: 'На мне',
                            link: 'on-me',
                            icon: 'mdi-arrow-right',
                            count: 0
                        },
                        {
                            name: 'От меня',
                            link: 'from-me',
                            icon: 'mdi-arrow-left',
                            count: 0
                        }
                    ],
                },
                regulations: [],
                currentTasks: [],
                taskStates: [],
                emptyTask: {
                    '@Task': -1,
                    'Regulation': null,
                    'Author': null,
                    'Executor': null,
                    'Conversation': null,
                    'History': null,
                    'Description': null,
                    'State': null,
                    'Content': null,
                },
                employees: [],
                taskFilter: 'all'
            }
        },
        methods: {
            loadFilters() {
                axios.get(getURL('admin/docflow/task/filters')).then(res => {
                    this.taskMenus = {...this.initialMenus, ...res.data};
                })
            },
            loadRegulations() {
                axios.get(getURL('admin/docflow/regulation/list')).then(res => {
                    this.regulations = res.data;
                })
            },
            loadEmployees() {
                axios.get(getURL('admin/user_list')).then(res => {
                    this.employees = res.data;
                })
            },
            createTask(regulation) {
                let newTask = {...this.emptyTask};
                newTask['Regulation'] = regulation['@Regulation'];
                newTask['action'] = 'create';
                axios.post(getURL('admin/docflow/task'), newTask, {withCredentials: true}).then(() => {
                    this.loadTasks();
                })
            },
            loadTasks(taskFilter) {
                this.taskLoading = true;
                axios.get(getURL('admin/docflow/task/list'), { params: { type: taskFilter || this.taskFilter },
                    withCredentials: true}).then(res => {
                    this.currentTasks = res.data;
                    this.taskLoading = false;
                }).catch(() => {
                    this.taskLoading = false;
                })
            },
            loadTaskStates() {
                axios.get(getURL('admin/docflow/task_states/list')).then(res => {
                    this.taskStates = res.data;
                })
            },
        },
        mounted() {
            this.loadFilters();
            this.loadRegulations();
            this.loadEmployees();
            this.loadTaskStates();
            this.loadTasks();

            eventBus.$on('docflow/task:update', () => {
                this.loadTasks();
                this.loadTaskStates();
            })
        },
        watch: {
            taskFilter: function(oldVal, newVal) {
                if(oldVal !== newVal) {
                    this.loadTasks();
                }
            }
        }
    }
</script>

<style scoped>

</style>
