<template>
    <v-card elevation="0">
        <v-card-title>
            Контент
        </v-card-title>
        <v-row>
            <v-col v-for="card of cards" :key="card['@Content']" cols="3">
                <v-card class="fill-height">
                    <v-card-actions>
                        <v-btn icon>
                            <v-icon color="orange">
                                mdi-refresh
                            </v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon>
                            <v-icon color="red">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-card-subtitle v-html="getStatic(card['Link'])"></v-card-subtitle>
                    <v-img :src="getStatic(card['Link'])" contain alt="content">

                    </v-img>

                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import axios from 'axios';
    import {getURL, getStatic} from "../../settings";
    export default {
        name: "Content",
        data: () => {
          return {
              cards: []
          }
        },
        mounted() {
            this.loadAllContent();
        },
        methods: {
            getStatic,
            loadAllContent() {
                axios.get(getURL('admin/content/load_all')).then(res => {
                    this.cards = res.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
