<template>
    <v-card elevation="0">
        <v-card-title>
            Тарифы
        </v-card-title>
    <tariff-edit></tariff-edit>
    </v-card>
</template>

<script>
    import TariffEdit from "../../components/hotel/TariffEdit";

    export default {
        components: {TariffEdit},

        name: "Tariffs"
    }
</script>

<style scoped>

</style>
