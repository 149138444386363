<template>
    <v-card style="width: 100%">
        <v-card-actions style="background: whitesmoke">
            <b class="mr-2" v-if="!isEdit"> {{this.regulation ? this.regulation.Name : ''}}</b>
                <v-select
                        v-else
                        :items="regulations"
                         item-text="Name"
                         item-value="@Regulation"
                        v-model="selfInfo['Regulation']"
                        style="max-width: 150px"
                        dense
                ></v-select>
            <span> </span>
            <b>
               #{{selfInfo['@Task']}}
            </b>
            <v-card-subtitle v-if="state" class="py-0" v-html="state.InProgressName">
            </v-card-subtitle>

            <v-spacer/>

            <div v-if="!final">
                <v-icon small color="orange" v-on:click="setEdit(true)" v-if="!isEdit">mdi-pencil</v-icon>
                <v-icon small color="green" v-on:click="saveTask()" v-if="isEdit">mdi-check</v-icon>
                <v-icon small color="red" v-on:click="deleteTask">mdi-close</v-icon>
            </div>
        </v-card-actions>
        <div class="d-flex flex-row justify-space-between">
            <div v-if="transitions && transitions.length > 0"  class="d-flex flex-row justify-start">
            <div class="d-flex flex-column justify-start" style="width: 10vw">
                <template>
                    <div class="d-flex flex-column">
                    <v-btn small v-for="state of transitions" :key="state['@TaskState']"
                           :color="(state.Color? state.Color : 'gray')"
                           v-on:click="moveTask(state)"
                    elevation="0"
                    >
                        {{state.Name}}
                    </v-btn>
                    <template v-if="reassignableState">
                        <v-btn :disabled="!newExecutor" small v-on:click="moveTask(state)"
                               elevation="0"
                        >
                            Переназначить
                        </v-btn>
                        <mini-profile :idx="newExecutor" v-if="newExecutor"></mini-profile>
                        <v-select
                                :items="ourEmployees"
                                item-text="Name"
                                item-value="@User"
                                class="px-2"
                                v-model="newExecutor"
                                dense
                        >

                        </v-select>

                    </template>
                    </div>
                </template>
            </div>
            <v-divider vertical ></v-divider>
            </div>
            <div style="min-width: 20vw; max-width: 25vw">
                <v-card-subtitle class="py-0">
                    Описание
                </v-card-subtitle>
                <v-card-subtitle v-if="!isEdit" v-html="selfInfo['Description']">

                </v-card-subtitle>
                <rich-editor-field class="px-4" v-else v-model="selfInfo['Description']">

                </rich-editor-field>
            </div>
            <div class="d-flex flex-row align-start">
                <v-divider vertical></v-divider>

                <div class="d-flex flex-column justify-space-between mx-2">
                    <v-card-subtitle class="pa-0">
                        Автор
                    </v-card-subtitle>
                    <mini-profile v-if="selfInfo['Author']" :idx="selfInfo['Author']" :show-role="true" :size="64"></mini-profile>
                    <v-divider></v-divider>
                    <v-card-subtitle class="pa-0">
                        Исполнитель
                    </v-card-subtitle>
                    <mini-profile v-if="selfInfo['Executor']" :idx="selfInfo['Executor']" :show-role="true" :size="64"></mini-profile>
                    <v-select v-else
                              :items="ourEmployees"
                              item-text="Name"
                              item-value="@User"
                              style="max-width: 150px"
                              v-on:change="setEdit(true)"
                              v-model="selfInfo['Executor']"
                              dense
                    ></v-select>
                </div>
            <v-divider vertical></v-divider>
            <div style="background: #f5f5f5; width: 20vw">
                <v-tabs v-model="selectedTab" height="32" color="green">
                    <v-tab v-for="tab of tabs" :key="`task-${selfInfo['@Task']}-tab-${tab}`">
                            {{tab}}
                    </v-tab>
                </v-tabs>
                <task-history v-if="selectedTab === 0" :idx="selfInfo['History']" style="max-height: 20vh; overflow-y: auto"/>
                <task-conversation v-if="selectedTab === 1" :idx="selfInfo['Conversation']"/>
            </div>
            </div>
        </div>
    </v-card>
</template>

<script>
    import axios from 'axios';
    import {getURL} from "../../settings";
    import MiniProfile from "../user/MiniProfile";
    import TaskHistory from "./TaskHistory";
    import TaskConversation from "./TaskConversation";
    import eventBus from "../../utils/eventBus";
    import RichEditorField from "../RichEditorField";

    export default {
        name: "HorizontalTask",
        components: {RichEditorField, TaskConversation, TaskHistory, MiniProfile},
        props: ['idx', 'info', 'employees', 'regulations', 'taskStates'],
        data: () => {
            return {
                selfInfo: {},
                isEdit: false,
                regulation: {},
                initialStates: [],
                transitions: [],
                state: null,
                tabs: ['Лента', 'Диалог'],
                selectedTab: 'Лента',
                reassignableState: false,
                newExecutor: null,
                final: false,
                ourEmployees: []
            }
        },
        mounted() {
            if(this.info) {
                this.parseInfo()
            } else if (this.idx) {
                this.loadInfo(this.idx);
            }
        },
        methods: {
            parseInfo() {
                this.selfInfo = this.info;
                this.regulation = this.regulations.find(o => o['@Regulation'] === this.selfInfo['Regulation']);
                const stateRecord = this.taskStates.find(o => o['@TaskState'] === this.selfInfo['State']);
                if (stateRecord) {
                    this.state = stateRecord;
                    this.final = this.state['Final'];
                    this.reassignableState = this.state.Reassignable;
                    const transitionsSet = new Set(stateRecord.Transitions);
                    this.transitions = this.taskStates.filter(o => transitionsSet.has(o['@TaskState']));
                }
                this.ourEmployees = this.employees.filter(o => o.Guest !== true);
            },
            loadInfo(idx) {
                axios.get(getURL(`task/${idx}`).then( resp=> {
                    this.selfInfo = resp.data;
                }))
            },

            setEdit(isEdit) {
                this.isEdit = isEdit;
            },
            saveTask() {
                let updateTask = this.selfInfo;
                updateTask['action'] = 'update';
                axios.post(getURL('admin/docflow/task'), updateTask, {withCredentials: true}).then(()=> {
                    this.isEdit = false;
                    eventBus.$emit('docflow/task:update');
                })
            },
            deleteTask() {
                let deleteRecord = {
                    '@Task': this.selfInfo['@Task'],
                    'action': 'delete'
                }
                axios.post(getURL('admin/docflow/task'), deleteRecord, {withCredentials: true}).then(()=> {
                    eventBus.$emit('docflow/task:update');
                })
            },
            moveTask(toState) {
                axios.post(getURL('admin/docflow/task/move'), {
                    newExecutor: this.newExecutor,
                    task: this.selfInfo,
                    toState: toState
                }, {withCredentials: true}).then(() => {
                    this.isEdit = false;
                    this.newExecutor = false;
                    eventBus.$emit('docflow/task:update');
                })
            }
        },
        watch: {
            regulations: function() {
                this.regulation = this.regulations.find(o => o['@Regulation'] === this.selfInfo['Regulation']);
                console.log('fuck1')

            },
            taskStates: function () {
                if(!this.regulation)
                    return;
                console.log('fuck2')

                const stateRecord = this.taskStates.find(o => o['@TaskState'] === this.selfInfo['State']);
                if (stateRecord) {
                    this.state = stateRecord;
                    this.final = this.state['Final'];
                    this.reassignableState = this.state.Reassignable;
                    const transitionsSet = new Set(stateRecord.Transitions || []);
                    this.transitions = this.taskStates.filter(o => transitionsSet.has(o['@TaskState']));
                }
            },
            info: function () {
                this.parseInfo();
            }
        }
    }
</script>

<style scoped>

</style>
