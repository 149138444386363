export const BLOCK_TYPES = {
    1: {name: 'block-1.png', info: 'Текст с заголовком'},
    2: {name: 'block-2.png', info: 'Текст с картинкой слева'},
    3: {name: 'block-3.png', info: 'Текст с картинкой справа'},
    4: {name: 'block-4.png', info: 'Заголовок и две картинки'},
    5: {name: 'block-5.png', info: 'Заголовок и три картинки'},
    6: {name: 'block-6.png', info: 'Цены'},
    7: {name: 'block-7.png', info: 'Слайдер с картинками'},
    8: {name: 'block-7.png', info: 'Слайдер с 3 активными элементами'},
    9: {name: 'block-7.png', info: 'Слайдер с товарами'},
    10: {name: 'block-7.png', info: 'Бронирование'},
    11: {name: 'block-11.png', info: 'Яндекс карты'},
    12: {name: 'block-7.png', info: 'Категории'}
}
