<template>
    <v-list color="transparent" class="px-2" style="height: 20vh;">
        <template v-if="activityHistory && activityHistory.length > 0">
            <v-list-item  v-for="msg of activityHistory" :key="msg['@HistoryAction']" :id="`ha-${msg['@HistoryAction']}`">
                <v-list-item-content>
                    <v-sheet color="transparent">
                        <v-card-subtitle class="pa-0" style="font-size: 12px;">{{msg.DateTime.slice(0, 20)}}</v-card-subtitle>
                        <div class="d-flex flex-row align-center">
                        <mini-profile :idx="msg.User" :size="16"></mini-profile>
                        <v-card-subtitle class="pa-0">
                            <v-icon small>mdi-arrow-right</v-icon>{{msg.Action}}
                        </v-card-subtitle>
                        </div>
                    </v-sheet>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-else>
            <p>Пока пусто</p>
        </template>
    </v-list>
</template>

<script>
    import axios from 'axios';
    import {getURL} from "../../settings";
    import MiniProfile from "../user/MiniProfile";
    import eventBus from "../../utils/eventBus";

    export default {
        name: "TaskHistory",
        components: {MiniProfile},
        props: ['idx'],
        data: () => {
            return {
                activityHistory: [],
                shouldUpdate: false,
            }
        },
        updated() {
            if(this.activityHistory.length > 0 && this.shouldUpdate)
            {
                const lastMessage = this.activityHistory[this.activityHistory.length - 1];
                const lastMessageId = `ha-${lastMessage['@HistoryAction']}`;
                const lastMessageElement = document.getElementById(lastMessageId);
                if(lastMessageElement) {
                    lastMessageElement.scrollIntoView({block: "nearest", inline: "nearest"});
                    this.shouldUpdate = false;
                }
            }
        },
        mounted() {
            this.loadHistory();

            eventBus.$on('docflow/task:update', () => {
                this.loadHistory();
            })
        },
        methods: {
            loadHistory() {
                if(!this.idx)
                    return;
                axios.get(getURL(`admin/docflow/history/${this.idx}`)).then(res => {
                    if(this.activityHistory.length !== res.data)
                        this.shouldUpdate = true
                    this.activityHistory = res.data;
                })
            }
        },
        watch: {
            idx: function() {
                this.loadHistory();
            }
        }
    }
</script>

<style scoped>

</style>
