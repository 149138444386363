<template>
    <v-card class="transparent config__main-pillow elevation-0">
        <v-sheet class="config__main-pillow d-flex flex-row">

            <v-card class="config__half-pillow elevation-0 ">
                <v-card-title>
                    Основные настройки
                    <v-spacer></v-spacer>
                    <v-btn color="green" class="mr-2" style="color: white" v-on:click="addItem()">Добавить <v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn color="orange" style="color: white" v-on:click="saveAllSettings">Обновить настройки</v-btn>

                </v-card-title>

                <v-treeview
                    :items="allSettings"
                    dense
                >
                    <template v-slot:prepend="{item}">
                        <div v-if="editItem!==item.id">
                        <v-icon color="green" small v-on:click="addItem(item)">mdi-plus</v-icon>
                        <v-icon color="orange" small v-on:click="editItem=item.id" >mdi-pencil</v-icon>
                        </div>
                        <div v-else>
                        <v-icon color="green" small v-on:click="editItem=null" >mdi-check</v-icon>
                            <v-icon color="red" small v-on:click="deleteItem(item)" >mdi-delete</v-icon>
                        </div>
                    </template>
                    <template v-slot:label="{item}">
                        <div v-if="editItem === item.id" class="d-flex flex-row">
                            <v-text-field dense v-model="item.name" class="mr-1"></v-text-field>
                            <v-combobox v-model="item.type" :items="types" dense label="Тип"></v-combobox>
                        </div>
                        <div v-else>
                            {{item.name}}:{{item.type}}
                        </div>
                    </template>
                    <template v-slot:append="{item}">
                        <v-text-field dense v-model="item.value" label="Значение"></v-text-field>
                    </template>
                </v-treeview>
            </v-card>

            <v-divider vertical></v-divider>
            <v-card class="config__half-pillow elevation-0 ">
                <v-card-title>
                    Отображение
                </v-card-title>
            </v-card>

        </v-sheet>
    </v-card>
</template>

<script>
    import axios from 'axios';
    import {getURL} from "../../settings";

    export default {
        name: "Config",
        data: () => {
            return {
                allSettings: [],
                settingsIndex: {},
                editItem: null,
                settingModel: {
                    id: -1,
                    name: null,
                    value: null,
                    type: null,
                    section: null,
                    children: null
                },
                types: [
                    'Component',
                    'Link',
                    'Links',
                    'Color',
                    'Background',
                    'vw',
                    'px',
                    'rem'
                ]
            }
        },
        mounted() {
            this.loadAllSettings();
        },
        methods: {
            loadAllSettings() {
                axios.get(getURL('admin/draft/settings')).then(resp => {
                    this.allSettings = resp.data;
                    this.allSettings.forEach(item => {
                        this.buildIndex(item);
                    })
                })
            },
            saveAllSettings() {
                this.editItem = null;

                axios.post(getURL('admin/draft/settings/save'), this.allSettings).then(() => {
                    this.loadAllSettings();
                })
            },
            buildIndex(item) {
                this.settingsIndex[item.id] = item;
                item.children.forEach(child => {
                    this.buildIndex(child);
                })
            },
            addItem(parent) {
                let child = {...this.settingModel};
                child.children = [];
                console.log(child);

                if( parent ) {
                    child.section = parent.id;
                    parent.children.push(child)
                } else {
                    this.allSettings.push(child);
                }

                this.settingsIndex[child.id] = child;
                this.editItem = child.id;
                this.settingModel.id --;
            },
            deleteItem(item) {
                if( item.section ) {
                    this.settingsIndex[item.section].children.splice(this.settingsIndex[item.section].children.indexOf(item), 1);
                }
                else {
                    this.allSettings.splice(this.allSettings.indexOf(item), 1);
                }

                if(item.id > 0) {
                    axios.delete(getURL(`admin/draft/settings/${item.id}`)).then(() => {
                        delete this.settingsIndex[item.id];
                    })
                }
            }
        }
    }
</script>

<style scoped>
.config__main-pillow {
    width: 100%;
    height: 100%;
}
    .config__half-pillow {
        width: 50%;
        height: 100%;
    }
</style>
